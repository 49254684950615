@charset "utf-8";
@theme-color: #4a6aa7; //主题色
@theme-font-family: 'MicrosoftYaHei';
:global {
  body {
    font-family: @theme-font-family;
  }
  .header {
    height: 1.2162rem;
    color: #fff;
    line-height: 1.2162rem;
    text-align: center;
    font-size: 0.4054rem;
    width: 100%;
    height: 1.3514rem;
  }
  .one {
    background: linear-gradient(to right, #0085ff, #0334d3);
  }
  .three {
    background: linear-gradient(to right, #ffa800, #f07f10);
  }
  .five {
    background: linear-gradient(to right, #f45e77, #f04059);
  }
  .title-more {
    text-align: center;
    font-size: 0.4324rem;
    font-family: @theme-font-family;
    color: rgb(234, 159, 33);
    display: inline-block;
    margin-left: 0.2703rem;
  }
  .title-three{
    text-align: center;
    font-size: 0.4324rem;
    font-family: @theme-font-family;
    color: #996600;
    display: inline-block;
    margin-left: 0.2703rem;
  }
  .close-button {
    color: #fff;
    width: 1.0811rem;
    height: 0.6486rem;
    display: inline-block;
    background-size: 100%;
    position: relative;
    right: 0.5405rem;
    font-size: 0.8108rem;
  }
  .productDetails {
    margin: 0.4054rem 0 0.4054rem 0;
    padding-top: 0.2703rem;
    padding-bottom: 0.2703rem;
    width: 9.2rem;
    font-family: Microsoft YaHei;
    background: #fff;
    border-radius: 15px;
     .product-warp{
      .am-flexbox-align-center{
        align-items: baseline;
      }
    }
    .left-first {
      background: url('./assets/productMes.png') 0.2703rem center no-repeat;
      background-size: 0.5405rem 0.5405rem;
      color: rgb(50, 50, 50);
      margin: 0.2703rem 0 0.2703rem 0;
      padding-left: 1.1081rem;
      font-size: 0.4324rem;
      text-align: left;
      height: 0.7027rem;
      line-height: 0.7027rem;
    }
    .left {
      color: rgb(67, 160, 255);
      margin:  0 0 10px 15px;
      font-size: 0.38rem;
      line-height: 0.38rem;
      text-align: left;
    }
    .right {
      color: rgb(50, 50, 50);
      font-size: 0.41rem;
      line-height: 0.5946rem;
      margin: 0 15px 10px 0;
      text-align: right;
    }
    .rightView{
      font-size: 0.41rem;
      line-height: 0.5946rem;
      margin: 0 15px 10px 0;
      text-align: right;
      color: rgb(67, 160, 255);
      cursor: pointer;
    }
    .right-down-warp {
      text-align: right;
      .right-down {
        width: 0.4054rem;
        height: 0.4054rem;
        background: url(./assets/down.png) 100% 0 no-repeat;
        display: inline-block;
        background-size: 100%;
        position: relative;
        right: 15px;
        top: 4px;
      }
      .right-up{
        width: 0.4054rem;
        height: 0.4054rem;
        background: url(./assets/up.png) 100% 0 no-repeat;
        display: inline-block;
        background-size: 100%;
        position: relative;
        right: 15px;
        top: 4px;
      }
    }
  }
  footer {
    text-align: center;
    margin: 1.0811rem 0 1.6216rem 0;
    a {
      color: #c4c4c4;
    }
  }
  .errorCode-warp {
    position: absolute;
    top: 21%;
    left: 23%;
    .errorCode {
      width: 5.9459rem;
      height: 5.4054rem;
      background: url(./assets/errorCode.png) no-repeat;
      background-size: 100% 100%;
    }
    .errorTime{
      width: 5.9459rem;
      height: 5.4054rem;
      background: url(./assets/errorTime.png) no-repeat;
      background-size: 100% 100%;
    }
    .errorText {
      font-size: 16px;
      color: #999;
      text-align: center;
      margin-top: 20px;
    }
  }
}
.modal-header {
  span {
    display: inline-block;
    position: absolute;
    top: 0.1081rem;
    right: 0.3784rem;
    font-size: 0.6757rem;
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;