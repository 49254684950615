.carouselWrap {
  padding: 0 0.5946rem 0.5946rem 0.5946rem;
}
.carouselWrap img {
  width: 100%;
  height: 100%;
  -moz-border-radius: 0.4054rem;
       border-radius: 0.4054rem;
}
.One {
  background: url(/static/media/bg.ac38210c.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.Three {
  background: url(/static/media/bg-2.c5a9242d.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.Five {
  background: url(/static/media/bg-3.3f966609.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.brandWrap {
  width: 9.2rem;
  text-align: center;
  padding: 0.8108rem 0 1.1622rem 0;
  -moz-border-radius: 0.4054rem;
       border-radius: 0.4054rem;
  background: #ffffff;
  -moz-background-size: auto 0.8108rem;
       background-size: auto 0.8108rem;
}
.brandWrap .brand-warp-content {
  width: 6.7568rem;
  margin: 0 auto;
  background-color: transparent;
}
.brandWrap .brand-warp-content span {
  font-size: 0.5405rem;
}
.brandWrap .brand-warp-content .brand-title {
  text-align: center;
  height: 0.5rem;
  font-size: 0.5rem;
  line-height: 0.5rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #2d2d2d;
}
.brandWrap .brand-warp-content .brand-mooncakes {
  font-size: 0.5405rem;
  font-family: Microsoft YaHei;
  color: #2d2d2d;
}
.brandWrap .brand-warp-content hr {
  background-color: #add6ff;
  height: 1px;
  border: none;
  opacity: 0.3;
}
.brandWrap .brand-warp-content div {
  line-height: 0.4595rem;
  padding: 0.4054rem;
  word-wrap: break-word;
  font-size: 14px;
}
.brandWrap .brand-warp-content div span {
  font-size: 16px;
  color: #aaaaaa;
}
.brandWrap .brand-warp-content div .blue {
  color: #43a0ff;
}
.brandWrap .brand-warp-content .brand-bum-bg {
  width: 5.7568rem;
  margin: 0 auto;
  background: url(/static/media/zhang.4a8d9ac8.png) 0 100% no-repeat;
  -moz-background-size: 45%;
       background-size: 45%;
  background-position: 146px;
}
.brandWrap .brand-warp-content .brand-bum-bg .brand-number {
  width: 4.2432rem;
  margin: 0 auto;
  background: url(/static/media/qukuai.11a699c6.png) 0 0 no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.antiWrap {
  width: 9.2rem;
  margin: 0.4324rem 0;
  padding: 0.8649rem 0 0 0;
  background: #ffffff;
  -moz-border-radius: 0.4054rem;
       border-radius: 0.4054rem;
}
.antiWrap .anti-warp-content {
  width: 8.1081rem;
  margin: 0 auto;
  background-color: transparent;
  color: #fff;
  text-align: center;
}
.antiWrap .anti-warp-content div {
  font-size: 0.2973rem;
  color: #a0a0a0;
  margin-top: 20px;
  line-height: 0.4865rem;
}
.antiWrap .anti-warp-content span img {
  width: 100%;
  height: 100%;
}
.antiWrap .antiWrap-button {
  width: 9.2rem;
  font-size: 0.2973rem;
  background: -webkit-linear-gradient(right, #2d7cf0, #7fccff);
  height: 0.8108rem;
  line-height: 0.8108rem;
  text-align: center;
  -moz-border-radius: 0px 0px 15px 15px;
       border-radius: 0px 0px 15px 15px;
  margin-top: 1.1622rem;
}
.home-modal-warp {
  width: 9.2703rem !important;
  text-align: center;
}
.home-modal-warp .am-modal-content {
  padding: 0 !important;
}
.home-modal-warp .am-modal-header {
  background: -webkit-gradient(linear, left top, right top, from(#7fcdff), to(#2d7bf0));
  background: -webkit-linear-gradient(left, #7fcdff, #2d7bf0);
  background: -moz-linear-gradient(left, #7fcdff, #2d7bf0);
  background: -o-linear-gradient(left, #7fcdff, #2d7bf0);
  background: linear-gradient(to right, #7fcdff, #2d7bf0);
  padding-top: 0.2703rem;
}
.home-modal-warp .am-modal-header .am-modal-title {
  color: #fff;
  font-size: 0.4324rem;
  line-height: 30px;
}
.home-modal-warp .am-modal-header .am-modal-title .modal-header span {
  display: inline-block;
  position: absolute;
  top: 0.1081rem;
  right: 0.3784rem;
  font-size: 0.6757rem;
}
.home-modal-warp .am-modal-header .modal-title-search {
  width: 4.3243rem;
  height: 4.3243rem;
  margin: 0 auto;
  margin-top: 0.6757rem;
  background: url(/static/media/query.513f527b.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.home-modal-warp .am-modal-header .modal-title-search div {
  font-size: 1.5946rem;
  color: #fff;
  position: relative;
  top: 36%;
}
.home-modal-warp .am-modal-close {
  color: #fff;
  padding: 10px;
}
.home-modal-warp .am-modal-body {
  background: url(/static/media/modal_bg.8bb3605b.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
  min-height: 5.4054rem;
}
.home-modal-warp .am-modal-body .modal-content-wrap {
  width: 8.1081rem;
  position: absolute;
  top: 47%;
}
.home-modal-warp .am-modal-body .modal-content-voice {
  width: 7.027rem;
  margin: 0 auto;
  font-size: 0.3784rem;
  color: #52b5ff;
  margin-bottom: 1.0811rem;
  margin-top: 35px;
  word-wrap: break-word;
  line-height: 25px;
}
.home-modal-warp .am-modal-body .modal-content-voice span {
  display: inline-block;
  width: 2.1892rem;
  height: 0.8108rem;
  line-height: 0.8108rem;
  padding-left: 0.2703rem;
  background: url(/static/media/video.237409e5.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.home-modal-warp .am-modal-body .modal-more-times {
  width: 7.8378rem;
  width: 280px;
  margin: 0 auto;
}
.home-modal-warp .am-modal-body .modal-more-times span {
  display: inline-block;
  color: #4a6aa7;
  font-size: 0.4324rem;
  margin: 0.5405rem 0 0.6757rem 0;
}
.home-modal-warp .am-modal-body .modal-more-times .modal-more-time {
  color: #4a6aa7;
  font-size: 0.3784rem;
  line-height: 0.5946rem;
  margin-top: 0.7027rem;
}
.home-modal-warp .am-modal-body .modal-more-times div img {
  width: 5.4054rem;
  height: auto;
  margin: 15px;
}
.home-modal-warp .am-modal-body .modal-more-times .modal-button {
  width: 6.0811rem;
  height: 1.027rem;
  line-height: 1.027rem;
  margin: 0 auto;
  color: #fff;
  background: url(/static/media/bt1.fc4b7ab6.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
  margin-top: 20px;
}
.home-modal-warp .am-modal-body .modal-more-times .inconformity {
  background: url(/static/media/bt2.a8166230.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
  margin-top: 0.2703rem;
}
.home-modal-warp .am-modal-body .modal-content-checked {
  height: 9.4595rem;
  overflow: auto;
  width: 7.2973rem;
  margin: 0 auto;
  margin-top: 10px;
}
.home-modal-warp .am-modal-body .modal-content-checked .icon-checked {
  width: 0.5405rem;
  height: 0.5405rem;
  background: url(/static/media/Checked.db1b85df.png);
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.home-modal-warp .am-modal-body .modal-content-checked .icon-unChecked {
  width: 0.5405rem;
  height: 0.5405rem;
  background: url(/static/media/yuan1.19aac037.png);
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.home-modal-warp .am-modal-body .checked-footer {
  font-size: 12px;
  color: #ccc;
  margin-top: 0.5405rem;
}
.home-modal-warp .am-modal-body .record-container {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  word-wrap: break-word;
}
.home-modal-warp .am-modal-body .record-container .left {
  -webkit-flex: 0 0 90px;
     -moz-box-flex: 0;
      -ms-flex: 0 0 90px;
          flex: 0 0 90px;
  color: #c4c4c4;
}
.home-modal-warp .am-modal-body .record-container .right {
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  word-wrap: break-word;
  width: 100px !important;
}
.home-modal-warp .modal-result-normal {
  width: 8.1081rem;
  position: absolute;
  top: 37%;
}
.home-modal-warp .modal-result-normal .modal-button {
  width: 6.0811rem;
  height: 1.027rem;
  line-height: 1.027rem;
  margin: 0 auto;
  color: #fff;
  background: url(/static/media/bt1.fc4b7ab6.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
  margin-top: 20px;
}
.home-modal-warp .modal-content-position {
  width: 8.1081rem;
  margin: 0 auto;
  margin-bottom: 20px;
}
.home-modal-warp .modal-content-position .modal-result-position {
  margin: 30px;
}
.home-modal-warp .modal-content-position .modal-loading {
  background: url(/static/media/loading.1abd53f8.png) no-repeat;
  -moz-background-size: 100%;
       background-size: 100%;
  width: 2.7027rem;
  height: 2.7027rem;
  margin: 0 auto;
}
.home-modal-warp .modal-content-position .modal-loading span {
  position: relative;
  top: 38%;
  left: 2%;
  color: #4a6aa7;
}
.imageWarp {
  position: relative;
  width: 6.2703rem;
  height: 7.027rem;
  margin: 0 auto;
}
.imageWarp .imageRefresh {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  margin: 0 !important;
  opacity: 0.8;
  color: #000 !important;
  top: -0.0811rem;
  text-align: center;
  margin-top: 10px !important;
}
.imageWarp .imageRefresh span {
  position: relative;
  top: 20px;
  display: block;
  font-size: 16px !important;
}
.imageWarp .imageRefresh img {
  width: 1.3514rem !important;
  position: relative;
  top: 1.0811rem;
}
.imageWarp img {
  width: 6.4703rem;
  height: auto;
}
.imageWarpModal {
  position: relative;
  width: 6.2703rem;
  height: 7.027rem;
  margin: 0 auto;
}
.imageWarpModal .imageRefreshModal {
  position: absolute;
  width: 84%;
  height: 83%;
  background: #fff;
  margin: 0 !important;
  opacity: 0.8;
  color: #000 !important;
  top: -0.0811rem;
  margin: 0.4054rem !important;
}
.imageWarpModal .imageRefreshModal span {
  position: relative;
  top: 20px;
  display: block;
  font-size: 16px !important;
  margin: 0 !important;
  color: #000 !important;
}
.imageWarpModal .imageRefreshModal img {
  width: 1.3514rem !important;
  position: relative;
  top: 1.0811rem;
}
.imageWarpModal img {
  width: 6.4703rem;
  height: auto;
}

@charset "utf-8";
body {
  font-family: 'MicrosoftYaHei';
}
.header {
  height: 1.2162rem;
  color: #fff;
  line-height: 1.2162rem;
  text-align: center;
  font-size: 0.4054rem;
  width: 100%;
  height: 1.3514rem;
}
.one {
  background: -webkit-gradient(linear, left top, right top, from(#0085ff), to(#0334d3));
  background: -webkit-linear-gradient(left, #0085ff, #0334d3);
  background: -moz-linear-gradient(left, #0085ff, #0334d3);
  background: -o-linear-gradient(left, #0085ff, #0334d3);
  background: linear-gradient(to right, #0085ff, #0334d3);
}
.three {
  background: -webkit-gradient(linear, left top, right top, from(#ffa800), to(#f07f10));
  background: -webkit-linear-gradient(left, #ffa800, #f07f10);
  background: -moz-linear-gradient(left, #ffa800, #f07f10);
  background: -o-linear-gradient(left, #ffa800, #f07f10);
  background: linear-gradient(to right, #ffa800, #f07f10);
}
.five {
  background: -webkit-gradient(linear, left top, right top, from(#f45e77), to(#f04059));
  background: -webkit-linear-gradient(left, #f45e77, #f04059);
  background: -moz-linear-gradient(left, #f45e77, #f04059);
  background: -o-linear-gradient(left, #f45e77, #f04059);
  background: linear-gradient(to right, #f45e77, #f04059);
}
.title-more {
  text-align: center;
  font-size: 0.4324rem;
  font-family: 'MicrosoftYaHei';
  color: #ea9f21;
  display: inline-block;
  margin-left: 0.2703rem;
}
.title-three {
  text-align: center;
  font-size: 0.4324rem;
  font-family: 'MicrosoftYaHei';
  color: #996600;
  display: inline-block;
  margin-left: 0.2703rem;
}
.close-button {
  color: #fff;
  width: 1.0811rem;
  height: 0.6486rem;
  display: inline-block;
  -moz-background-size: 100%;
       background-size: 100%;
  position: relative;
  right: 0.5405rem;
  font-size: 0.8108rem;
}
.productDetails {
  margin: 0.4054rem 0 0.4054rem 0;
  padding-top: 0.2703rem;
  padding-bottom: 0.2703rem;
  width: 9.2rem;
  font-family: Microsoft YaHei;
  background: #fff;
  -moz-border-radius: 15px;
       border-radius: 15px;
}
.productDetails .product-warp .am-flexbox-align-center {
  -webkit-align-items: baseline;
     -moz-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.productDetails .left-first {
  background: url(/static/media/productMes.e2e408c5.png) 0.2703rem center no-repeat;
  -moz-background-size: 0.5405rem 0.5405rem;
       background-size: 0.5405rem 0.5405rem;
  color: #323232;
  margin: 0.2703rem 0 0.2703rem 0;
  padding-left: 1.1081rem;
  font-size: 0.4324rem;
  text-align: left;
  height: 0.7027rem;
  line-height: 0.7027rem;
}
.productDetails .left {
  color: #43a0ff;
  margin: 0 0 10px 15px;
  font-size: 0.38rem;
  line-height: 0.38rem;
  text-align: left;
}
.productDetails .right {
  color: #323232;
  font-size: 0.41rem;
  line-height: 0.5946rem;
  margin: 0 15px 10px 0;
  text-align: right;
}
.productDetails .rightView {
  font-size: 0.41rem;
  line-height: 0.5946rem;
  margin: 0 15px 10px 0;
  text-align: right;
  color: #43a0ff;
  cursor: pointer;
}
.productDetails .right-down-warp {
  text-align: right;
}
.productDetails .right-down-warp .right-down {
  width: 0.4054rem;
  height: 0.4054rem;
  background: url(/static/media/down.848f6433.png) 100% 0 no-repeat;
  display: inline-block;
  -moz-background-size: 100%;
       background-size: 100%;
  position: relative;
  right: 15px;
  top: 4px;
}
.productDetails .right-down-warp .right-up {
  width: 0.4054rem;
  height: 0.4054rem;
  background: url(/static/media/up.8c4c8337.png) 100% 0 no-repeat;
  display: inline-block;
  -moz-background-size: 100%;
       background-size: 100%;
  position: relative;
  right: 15px;
  top: 4px;
}
footer {
  text-align: center;
  margin: 1.0811rem 0 1.6216rem 0;
}
footer a {
  color: #c4c4c4;
}
.errorCode-warp {
  position: absolute;
  top: 21%;
  left: 23%;
}
.errorCode-warp .errorCode {
  width: 5.9459rem;
  height: 5.4054rem;
  background: url(/static/media/errorCode.8e47d537.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.errorCode-warp .errorTime {
  width: 5.9459rem;
  height: 5.4054rem;
  background: url(/static/media/errorTime.48306707.png) no-repeat;
  -moz-background-size: 100% 100%;
       background-size: 100% 100%;
}
.errorCode-warp .errorText {
  font-size: 16px;
  color: #999;
  text-align: center;
  margin-top: 20px;
}
.modal-header___u_ENn span {
  display: inline-block;
  position: absolute;
  top: 0.1081rem;
  right: 0.3784rem;
  font-size: 0.6757rem;
}

