:global {
  .carouselWrap {
    padding: 0 0.5946rem 0.5946rem 0.5946rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.4054rem;
    }
  }
  .One {
    background: url('./../assets/bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .Three {
    background: url('./../assets/bg-2.png') no-repeat;
    background-size: 100% 100%;
  }
  .Five {
    background: url('./../assets/bg-3.png') no-repeat;
    background-size: 100% 100%;
  }
  .brandWrap {
    width: 9.2rem;
    text-align: center;
    padding: 0.8108rem 0 1.1622rem 0;
    border-radius: 0.4054rem;
    background: #ffffff;
    background-size: auto 0.8108rem;
    .brand-warp-content {
      width: 6.7568rem;
      margin: 0 auto;
      background-color: transparent;
      span {
        font-size: 0.5405rem;
      }
      .brand-title {
        text-align: center;
        height: 0.5rem;
        font-size: 0.5rem;
        line-height: 0.5rem;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(45, 45, 45, 1);
      }
      .brand-mooncakes {
        font-size: 0.5405rem;
        font-family: Microsoft YaHei;
        color: rgb(45, 45, 45);
      }
      hr {
        background-color: rgb(173, 214, 255);
        height: 1px;
        border: none;
        opacity: 0.3;
      }
      div {
        line-height: 0.4595rem;
        padding: 0.4054rem;
        word-wrap: break-word;
        font-size: 14px;
        span {
          font-size: 16px;
          color: rgb(170, 170, 170);
        }
        .blue {
          color: rgb(67, 160, 255);
        }
      }
      .brand-bum-bg {
        width: 5.7568rem;
        margin: 0 auto;
        background: url('./../assets/zhang.png') 0 100% no-repeat;
        background-size: 45%;
        background-position: 146px;
        .brand-number {
          width: 4.2432rem;
          margin: 0 auto;
          background: url('./../assets/qukuai.png') 0 0 no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .antiWrap {
    width: 9.2rem;
    margin: 0.4324rem 0;
    padding: 0.8649rem 0 0 0;
    background: #ffffff;
    border-radius: 0.4054rem;
    .anti-warp-content {
      width: 8.1081rem;
      margin: 0 auto;
      background-color: transparent;
      color: #fff;
      text-align: center;
      div {
        font-size: 0.2973rem;
        color: rgb(160, 160, 160);
        margin-top: 20px;
        line-height: 0.4865rem;
      }
      span {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .antiWrap-button {
      width: 9.2rem;
      font-size: 0.2973rem;
      background: -webkit-linear-gradient(right, #2d7cf0, #7fccff);
      height: 0.8108rem;
      line-height: 0.8108rem;
      text-align: center;
      border-radius: 0px 0px 15px 15px;
      margin-top: 1.1622rem;
    }
  }
  .home-modal-warp {
    width: 9.2703rem !important;
    text-align: center;
    .am-modal-content {
      padding: 0 !important;
    }
    .am-modal-header {
      background: linear-gradient(to right, #7fcdff, #2d7bf0);
      padding-top: 0.2703rem;
      .am-modal-title {
        color: #fff;
        font-size: 0.4324rem;
        line-height: 30px;
        .modal-header {
          span {
            display: inline-block;
            position: absolute;
            top: 0.1081rem;
            right: 0.3784rem;
            font-size: 0.6757rem;
          }
        }
      }
      .modal-title-search {
        width: 4.3243rem;
        height: 4.3243rem;
        margin: 0 auto;
        margin-top: 0.6757rem;
        background: url(../assets/query.png) no-repeat;
        background-size: 100% 100%;
        div {
          font-size: 1.5946rem;
          color: #fff;
          position: relative;
          top: 36%;
        }
      }
    }
    .am-modal-close {
      color: #fff;
      padding: 10px;
    }
    .am-modal-body {
      background: url(../assets/modal_bg.png) no-repeat;
      background-size: 100% 100%;
      min-height: 5.4054rem;
      .modal-content-wrap {
        width: 8.1081rem;
        position: absolute;
        top: 47%;
      }
      .modal-content-voice {
        width: 7.027rem;
        margin: 0 auto;
        font-size: 0.3784rem;
        color: rgb(82, 181, 255);
        margin-bottom: 1.0811rem;
        margin-top: 35px;
        word-wrap: break-word;
        line-height: 25px;
        span {
          display: inline-block;
          width: 2.1892rem;
          height: 0.8108rem;
          line-height: 0.8108rem;
          padding-left: 0.2703rem;
          background: url(../assets/video.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      .modal-more-times {
        width: 7.8378rem;
        width: 280px;
        margin: 0 auto;
        span {
          display: inline-block;
          color: #4a6aa7;
          font-size: 0.4324rem;
          margin: 0.5405rem 0 0.6757rem 0;
        }
        .modal-more-time {
          color: #4a6aa7;
          font-size: 0.3784rem;
          line-height: 0.5946rem;
          margin-top: 0.7027rem;
        }
        div {
          img {
            width: 5.4054rem;
            height: auto;
            margin: 15px;
          }
        }
        .modal-button {
          width: 6.0811rem;
          height: 1.027rem;
          line-height: 1.027rem;
          margin: 0 auto;
          color: #fff;
          background: url(../assets/bt1.png) no-repeat;
          background-size: 100% 100%;
          margin-top: 20px;
        }
        .inconformity {
          background: url(../assets/bt2.png) no-repeat;
          background-size: 100% 100%;
          margin-top: 0.2703rem;
        }
      }
      .modal-content-checked {
        height: 9.4595rem;
        overflow: auto;
        width: 7.2973rem;
        margin: 0 auto;
        margin-top: 10px;
        .icon-checked {
          width: 0.5405rem;
          height: 0.5405rem;
          background: url(../assets/Checked.png);
          background-size: 100% 100%;
        }
        .icon-unChecked {
          width: 0.5405rem;
          height: 0.5405rem;
          background: url(../assets/yuan1.png);
          background-size: 100% 100%;
        }
      }
      .checked-footer {
        font-size: 12px;
        color: #ccc;
        margin-top: 0.5405rem;
      }
      .record-container {
        display: flex;
        text-align: left;
        word-wrap: break-word;
        .left {
          flex: 0 0 90px;
          color: #c4c4c4;
        }
        .right {
          flex: 1;
          word-wrap: break-word;
          width: 100px !important;
        }
      }
    }
    .modal-result-normal {
      width: 8.1081rem;
      position: absolute;
      top: 37%;
      .modal-button {
        width: 6.0811rem;
        height: 1.027rem;
        line-height: 1.027rem;
        margin: 0 auto;
        color: #fff;
        background: url(../assets/bt1.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;
      }
    }
    .modal-content-position {
      width: 8.1081rem;
      margin: 0 auto;
      margin-bottom: 20px;
      .modal-result-position {
        margin: 30px;
      }
      .modal-loading {
        background: url(../assets/loading.png) no-repeat;
        background-size: 100%;
        width: 2.7027rem;
        height: 2.7027rem;
        margin: 0 auto;
        span {
          position: relative;
          top: 38%;
          left: 2%;
          color: #4a6aa7;
        }
      }
    }
  }
  .imageWarp {
    position: relative;
    width: 6.2703rem;
    height: 7.027rem;
    margin: 0 auto;
    .imageRefresh {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff;
      margin: 0 !important;
      opacity: 0.8;
      color: #000 !important;
      top: -0.0811rem;
      text-align: center;
      margin-top: 10px !important;
      span {
        position: relative;
        top: 20px;
        display: block;
        font-size: 16px !important;
      }
      img {
        width: 1.3514rem !important;
        position: relative;
        top: 1.0811rem;
      }
    }
    img {
      width: 6.4703rem;
      height: auto;
    }
  }
  .imageWarpModal {
    position: relative;
    width: 6.2703rem;
    height: 7.027rem;
    margin: 0 auto;
    .imageRefreshModal {
      position: absolute;
      width: 84%;
      height: 83%;
      background: #fff;
      margin: 0 !important;
      opacity: 0.8;
      color: #000 !important;
      top: -0.0811rem;
      margin: 0.4054rem !important;
      span {
        position: relative;
        top: 20px;
        display: block;
        font-size: 16px !important;
        margin: 0 !important;
        color: #000 !important;
      }
      img {
        width: 1.3514rem !important;
        position: relative;
        top: 1.0811rem;
      }
    }
    img {
      width: 6.4703rem;
      height: auto;
    }
  }
}

@primary-color: #3296fa;@card-actions-background: #f5f8fa;@link-color: #3296fa;@link-hover-color: #89c6ff;@link-active-color: #2c83db;@text-color: #333333;@form-item-margin-bottom: 26px;@body-background: #f3f3f3;